/* You can add global styles to this file, and also import other style files */
@font-face {
  font-family: 'GothaProMed';
  src: url('assets/fonts/GothaProMed.otf') format('opentype');
  src: url('assets/fonts/GothamPro-Medium.woff') format('woff');
  font-weight: 600;
}

@font-face {
  font-family: 'GothaProReg';
  src: url('assets/fonts/GothaProReg.otf') format('opentype');
  src: url('assets/fonts/GothamPro.woff') format('woff');
  font-weight: 400;
}

@import "~@angular/material/prebuilt-themes/indigo-pink.css";

body {
  margin: 0;
  padding: 0;
}

html, body {
  height: 100%;
  min-height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-checkbox-ripple .mat-ripple-element,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #498FCB !important;
}

.mat-button {
  background-color: #498FCB !important;
}

.btn {
  margin-left: 20px;
}
